import React from 'react';
import styled from '@emotion/styled';
import {Logo} from '../Logo';
import {TopSearch} from './TopSearch';
import {TopNavButtons} from './Navigation/TopNav';
import {Primary40, White} from '../../../../styles/Colors';

const StyledTopBar = styled.div`
  height: 64px;
  width: 100%;
  background-color: ${White};
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid ${Primary40};
`;

const TopBarContents = styled.div`
  width: 100%;
  height: 64px;
  max-width: 1280px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export function TopBar() {
  return (
    <StyledTopBar>
      <TopBarContents>
        <Logo />
        <TopSearch />
        <TopNavButtons />
      </TopBarContents>
    </StyledTopBar>
  );
}
