const Background = '#ffffff';

const Primary = '#285f84';
const PrimaryLight = '#5b8cb4';
const PrimaryDark = '#003557';

const Secondary = '#f28e1b';
const SecondaryLight = '#ffbf51';
const SecondaryDark = '#b96000';

const TextOnBackground = '#000000';
const TextOnPrimary = '#ffffff';
const TextOnSecondary = '#000000';

const Primary10 = '#004A6F';
const Primary20 = '#005479';
const Primary30 = '#015E83';
const Primary40 = '#0B688D';
const Primary50 = '#157297';
const Primary60 = '#1F7CA1';
const Primary70 = '#2986AB';
const Primary80 = '#3390B5';
const Primary90 = '#3D9ABF';

const Greyscale30 = '#414B58';
const Greyscale50 = '#6C7D93';
const Greyscale60 = '#8A97A8';
const Greyscale70 = '#A7B1BE';
const Greyscale80 = '#C4CBD4';
const Greyscale90 = '#E2E5E9';

const White = '#FCFCFC';

export {
  Background,
  Primary,
  PrimaryLight,
  PrimaryDark,
  Primary10,
  Primary20,
  Primary30,
  Primary40,
  Primary50,
  Primary60,
  Primary70,
  Primary80,
  Primary90,
  Secondary,
  SecondaryLight,
  SecondaryDark,
  TextOnBackground,
  TextOnPrimary,
  TextOnSecondary,
  Greyscale30,
  Greyscale50,
  Greyscale60,
  Greyscale70,
  Greyscale80,
  Greyscale90,
  White,
};
