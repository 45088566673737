import React from 'react'

const Error = ({errorMessage}: {errorMessage: string}) => {
  if (errorMessage.length > 0) {
    return <div role="alert">Error: {errorMessage}</div>
  } else {
    return <div role="alert"></div>
  }
}

export default Error
