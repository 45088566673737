import styled from '@emotion/styled';
import {PrimaryDark, PrimaryLight} from '../styles/Colors';

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;

  fieldset {
    border: none;
  }

  [type='color'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local'],
  [type='email'],
  [type='month'],
  [type='number'],
  [type='password'],
  [type='search'],
  [type='tel'],
  [type='text'],
  [type='url'],
  [type='week'],
  [type='time'] select,
  textarea {
    padding: 0.8rem;
    background: transparent;
    vertical-align: middle;
    width: 100%;
    max-width: 100%;
    border: 1px solid ${PrimaryLight};
    color: ${PrimaryDark};
    border-radius: 4px;
    font-size: 1.15rem;
  }

  label {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.4rem;
  }
`;

export default Form;
