import React from 'react';
import AuthenticatedApp from './shared/app/AuthenticatedApp';
import UnauthenticatedApp from './shared/app/UnauthenticatedApp';
import {useAuth} from './shared/hooks/useAuth';

function App() {
  const {authToken} = useAuth();
  if (authToken !== null) {
    return <AuthenticatedApp />;
  } else {
    return <UnauthenticatedApp />;
  }
}

export default App;
