import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {
  AuthenticatedHomePage,
  LoginPage,
  SignupPage,
  VerifiedPage,
  VerifyPage,
} from '../../pages';
import {DiscoverPage} from '../../pages/Discover/DiscoverPage/DiscoverPage';

function AuthenticatedApp() {
  return (
    <Switch>
      <Route path="/verify-email">
        <VerifiedPage />
      </Route>
      <Route path="/verify">
        <VerifyPage />
      </Route>
      <Route path="/signup">
        <SignupPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/discover">
        <DiscoverPage />
      </Route>
      <Route path="/">
        <AuthenticatedHomePage />
      </Route>
    </Switch>
  );
}

export default AuthenticatedApp;
