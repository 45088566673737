import React, {useContext, createContext} from 'react';
import {gql, useQuery} from '@apollo/client';
import {GetAuthToken} from './__generated__/GetAuthToken';
import {useAuth} from './useAuth';

type LoginContextType = {
  signin: () => void;
};

const LoginContext = createContext<LoginContextType>({
  signin: () => {},
});

export const useLogin = () => {
  return useContext(LoginContext);
};

export const ProvideLogin = (props: any) => {
  const login = useProvideLogin();
  return (
    <LoginContext.Provider value={login}>
      {props.children}
    </LoginContext.Provider>
  );
};

const GET_AUTH_TOKEN = gql`
  query GetAuthToken {
    getAuthToken {
      ... on AuthToken {
        token
      }
    }
  }
`;

function useProvideLogin() {
  let {decodeAuthToken, authToken} = useAuth();
  const {refetch} = useQuery<GetAuthToken>(GET_AUTH_TOKEN, {
    skip: authToken != null,
    onCompleted: data => {
      console.log(data.getAuthToken);
      let token = data.getAuthToken;
      if (token != null) {
        if (token.__typename === 'AuthToken') {
          decodeAuthToken(token.token);
        }
      }
    },
  });

  const signin = () => {
    refetch();
  };

  return {
    signin,
  };
}
