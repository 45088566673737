import React, {useContext, createContext, useState} from 'react';
import jwt from 'jsonwebtoken';

export type AuthToken = {
  expirationDate: Date;
  userId: string;
  name: string;
};

export type AuthContextType = {
  authToken: AuthToken | null;
  encodedToken: string | null;
  decodeAuthToken: (token: string) => boolean;
};

const AuthContext = createContext<AuthContextType>({
  authToken: null,
  encodedToken: null,
  decodeAuthToken: (token: string) => {
    return false;
  },
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProvideAuth = (props: any) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
};

function useProvideAuth() {
  const [authToken, setAuthToken] = useState<AuthToken | null>(null);
  const [encodedToken, setEncodedToken] = useState<string | null>(null);

  const decodeAuthToken = (authToken: string) => {
    let decoded = jwt.decode(authToken);
    if (decoded === null) return false;
    setEncodedToken(authToken);
    setAuthToken(decoded as AuthToken);
    return true;
  };

  return {
    authToken,
    encodedToken,
    decodeAuthToken,
  };
}
