import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import GlobalStyles from './shared/styles/GlobalStyling';
import {Global} from '@emotion/react';
import {ProvideAuth, useAuth} from './shared/hooks/useAuth';
import PropTypes from 'prop-types';
import {ProvideLogin} from './shared/hooks/useLogin';

function CustomApolloProvider(props) {
  let {encodedToken} = useAuth();

  const link = createHttpLink({
    uri:
      process.env.REACT_APP_GRAPHQL_API_URL || 'http://localhost:8800/graphql',
    credentials: 'include',
  });

  console.log(encodedToken);
  const authLink = setContext((_, {headers}) => {
    let token = encodedToken;
    console.log(`Auth Token ${token}`);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
}

CustomApolloProvider.propTypes = {
  children: PropTypes.node,
};

ReactDOM.render(
  <ProvideAuth>
    <CustomApolloProvider>
      <React.StrictMode>
        <ProvideLogin>
          <Router>
            <Global styles={GlobalStyles} />
            <App />
          </Router>
        </ProvideLogin>
      </React.StrictMode>
    </CustomApolloProvider>
  </ProvideAuth>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
