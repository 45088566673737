import {gql, useMutation} from '@apollo/client';
import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import Button from '../../../shared/components/button';
import Error from '../../../shared/components/error';
import Form from '../../../shared/components/form';
import useForm from '../../../shared/hooks/useForm';
import {Signup, SignupVariables} from './__generated__/Signup';

const SIGNUP_MUTATION = gql`
  mutation Signup(
    $name: String!
    $email: String!
    $password: String!
    $dateOfBirth: String!
  ) {
    signup(
      name: $name
      email: $email
      password: $password
      dateOfBirth: $dateOfBirth
    ) {
      ... on SignupSuccess {
        user {
          name
        }
      }
    }
  }
`;

function useSignupComponent(
  initialState = {
    email: '',
    password: '',
    name: '',
    dateOfBirth: '',
  },
) {
  const {inputs, handleChange} = useForm(initialState);
  const [error, setError] = useState('');
  const [emailError] = useState('');

  function validateInputs(): boolean {
    if (inputs.email === '') {
      setError('Email Cannot be Empty');
      return false;
    }
    if (inputs.name === '') {
      setError('Name Cannot be Empty');
      return false;
    }
    if (inputs.password === '') {
      setError('Password Cannot be Empty');
      return false;
    }
    if (inputs.password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    if (!inputs.password.match(/\d+/g)) {
      setError('Password must have at least one number');
      return false;
    }
    if (inputs.password.toLowerCase() === inputs.password) {
      setError('Password must have at least one uppercase letter');
      return false;
    }
    if (inputs.password.toUpperCase() === inputs.password) {
      setError('Password must have at least one lowercase letter');
      return false;
    }
    return true;
  }

  const [signup, {data, loading}] = useMutation<Signup, SignupVariables>(
    SIGNUP_MUTATION,
  );

  function onSubmit() {
    if (!validateInputs()) {
      return;
    }
    signup({
      variables: {
        email: inputs.email,
        name: inputs.name,
        password: inputs.password,
        dateOfBirth: inputs.dateOfBirth,
      },
    });
  }

  return {
    models: {inputs, emailError, error, loading, data},
    operations: {handleChange, onSubmit},
  };
}

const SignupForm = () => {
  const {models, operations} = useSignupComponent();
  const {inputs, error, loading, data} = models;
  const {handleChange, onSubmit} = operations;

  function handleSubmit(e: any) {
    e.preventDefault();
    onSubmit();
  }

  if (loading) {
    return <div>Submitting...</div>;
  }

  if (!loading && data && data.signup.__typename === 'SignupSuccess') {
    console.log('test');
    return <Redirect to="/verify" />;
  }

  return (
    <Form method="post" onSubmit={handleSubmit}>
      <fieldset>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Name"
          autoComplete="name"
          value={inputs.name}
          onChange={handleChange}
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your email address"
          autoComplete="email"
          value={inputs.email}
          onChange={handleChange}
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          placeholder="Password"
          autoComplete="password"
          value={inputs.password}
          onChange={handleChange}
        />

        <label htmlFor="dateOfBirth">Date Of Birth</label>
        <input
          id="dateOfBirth"
          type="date"
          name="dateOfBirth"
          placeholder="Date Of Birth"
          value={inputs.dateOfBirth}
          onChange={handleChange}
        />
        <Button type="submit">Signup</Button>
      </fieldset>

      <Error errorMessage={error} />
    </Form>
  );
};

export {SignupForm, useSignupComponent};
