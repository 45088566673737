import React from 'react';
import {SignupForm} from '../SignupForm/SignupForm';

export function SignupPage() {
  return (
    <div>
      <SignupForm />
    </div>
  );
}
