import styled from '@emotion/styled';
import React from 'react';
import {Link} from 'react-router-dom';
import {Greyscale90} from '../../../styles/Colors';

const LogoLink = styled(Link)`
  height: 64px;
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 1px;
  flex-grow: 0;
  flex-shrink: 0;

  :hover {
    background-color: ${Greyscale90};
  }
`;

const LogoImg = styled.img`
  height: 48px;
  margin: 8px 16px;
`;

export function Logo() {
  return (
    <LogoLink to="/">
      <LogoImg src="Logo.svg" />
    </LogoLink>
  );
}
