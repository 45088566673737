import styled from '@emotion/styled';
import React from 'react';
import {Greyscale30, Primary70, Greyscale90} from '../../../../styles/Colors';

const StyledSearchBar = styled.input`
  background: ${Greyscale90};
  border: 1px solid ${Primary70};
  box-sizing: border-box;
  border-radius: 12px;
  max-width: 500px;
  height: 40px;
  flex-grow: 1;
  outline: none;
  padding: 0px 16px;
  margin-left: 12px;
  margin-right: 12px;
  color: ${Greyscale30};
`;

export function TopSearch() {
  return <StyledSearchBar type="text" placeholder="Search..." />;
}
