import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {
  UnauthenticatedHomePage,
  // HomePage,
  LoginPage,
  SignupPage,
  VerifiedPage,
  VerifyPage,
} from '../../pages';

function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/verify-email">
        <VerifiedPage />
      </Route>
      <Route path="/verify">
        <VerifyPage />
      </Route>
      <Route path="/signup">
        <SignupPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/">
        <UnauthenticatedHomePage />
      </Route>
    </Switch>
  );
}

export default UnauthenticatedApp;
