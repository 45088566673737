import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Layout} from '../../../shared/components/layout/Layout';

const VIEWER = gql`
  query Viewer {
    viewer {
      name
    }
  }
`;

export function AuthenticatedHomePage() {
  const {data, loading, error} = useQuery(VIEWER);
  if (error) return <div>${error.message}</div>;
  return (
    <div>
      <Layout>{loading ? '' : data.viewer.name}</Layout>
    </div>
  );
}
