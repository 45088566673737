import React from 'react';
import {Layout} from '../../../shared/components/layout/Layout';
import {LoginForm} from '../LoginForm/LoginForm';

export function LoginPage() {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
}
