import styled from '@emotion/styled';
import {
  Secondary,
  SecondaryDark,
  SecondaryLight,
  TextOnSecondary,
} from '../styles/Colors';

const Button = styled.button`
  display: inline-block;
  margin: 16px;
  border-radius: 8px;
  border: 1px solid ${SecondaryLight};
  padding: 16px;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${TextOnSecondary};
  background-color: ${Secondary};
  text-transform: uppercase;

  :hover {
    border: 1px solid ${SecondaryDark};
    cursor: pointer;
    color: #222222;
  }
`;

export default Button;
