import {gql, useMutation} from '@apollo/client';
import React, {useState} from 'react';
import Button from '../../../shared/components/button';
import Error from '../../../shared/components/error';
import Form from '../../../shared/components/form';
import {useAuth} from '../../../shared/hooks/useAuth';
import useForm from '../../../shared/hooks/useForm';
import {Login, LoginVariables} from './__generated__/Login';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      authToken
    }
  }
`;

function useLoginComponent(
  initialState = {
    email: '',
    password: '',
  },
) {
  const {inputs, handleChange} = useForm(initialState);
  const [error, setError] = useState('');
  const [emailError] = useState('');
  const {decodeAuthToken} = useAuth();

  function validateInputs(): boolean {
    if (inputs.email === '') {
      setError('Email Cannot be Empty');
      return false;
    }
    if (inputs.password === '') {
      setError('Password Cannot be Empty');
      return false;
    }
    return true;
  }

  const [login, {data, loading}] = useMutation<Login, LoginVariables>(
    LOGIN_MUTATION,
  );

  function onSubmit() {
    if (!validateInputs()) {
      return;
    }
    login({
      variables: {
        email: inputs.email,
        password: inputs.password,
      },
      onCompleted: data => {
        if (data.login.authToken !== null) {
          decodeAuthToken(data.login.authToken);
        }
      },
    });
  }

  return {
    models: {inputs, emailError, error, loading, data},
    operations: {handleChange, onSubmit},
  };
}

const LoginForm = () => {
  const {models, operations} = useLoginComponent();
  const {inputs, error, loading, data} = models;
  const {handleChange, onSubmit} = operations;

  function handleSubmit(e: any) {
    e.preventDefault();
    onSubmit();
  }

  if (loading) {
    return <div>Submitting...</div>;
  }

  if (!loading && data && data.login.authToken !== null) {
    console.log(data.login.authToken);
  }

  return (
    <Form method="post" onSubmit={handleSubmit}>
      <fieldset>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your email address"
          autoComplete="email"
          value={inputs.email}
          onChange={handleChange}
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          placeholder="Password"
          autoComplete="password"
          value={inputs.password}
          onChange={handleChange}
        />

        <Button type="submit">Signup</Button>
      </fieldset>

      <Error errorMessage={error} />
    </Form>
  );
};

export {LoginForm, useLoginComponent};
