import React from 'react';
import {BurgerNav} from './BurgerNav';
import {ListNav} from './ListNav';

export interface NavMenuItem {
  title: string;
  link: string;
}

export function TopNavButtons() {
  const menuItems: NavMenuItem[] = [
    {title: 'Discover', link: '/discover'},
    {title: 'Podcasts', link: '/podcasts'},
    {title: 'Profile', link: '/profile'},
    {title: 'Settings', link: '/settings'},
  ];

  return (
    <>
      <ListNav menuItems={menuItems} />
      <BurgerNav menuItems={menuItems} />
    </>
  );
}
