import React, {useState} from 'react';

export default function useForm<T>(initial: T) {
  const [inputs, setInputs] = useState(initial);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let {value, name, type} = e.target;

    let formValue;
    if (type === 'number') {
      formValue = parseInt(value);
    } else if (type === 'file') {
      formValue = [];
      formValue[0] = e.target.files;
    } else {
      formValue = value;
    }

    setInputs({
      ...inputs,
      [name]: formValue,
    });
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.entries(inputs).map(([key]) => [key, '']);
    setInputs(Object.fromEntries(blankState));
  }

  return {inputs, handleChange, resetForm, clearForm};
}
